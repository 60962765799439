export default [
  {
    "code": "0143",
    "name": "Δ.Ο.Υ. ΓΑΛΑΤΣΙΟΥ"
  },
  {
    "code": "1101",
    "name": "Α ΑΘΗΝΩΝ"
  },
  {
    "code": "1102",
    "name": "ΑΘΗΝΩΝ Β'"
  },
  {
    "code": "1103",
    "name": "ΑΘΗΝΩΝ Γ'"
  },
  {
    "code": "1104",
    "name": "Δ ΑΘΗΝΩΝ"
  },
  {
    "code": "1105",
    "name": "Ε΄ ΑΘΗΝΩΝ (Ε΄,Θ΄)"
  },
  {
    "code": "1106",
    "name": "ΑΘΗΝΩΝ ΣΤ'"
  },
  {
    "code": "1107",
    "name": "ΑΘΗΝΩΝ Ζ'"
  },
  {
    "code": "1108",
    "name": "ΑΘΗΝΩΝ Η'"
  },
  {
    "code": "1109",
    "name": "ΑΘΗΝΩΝ Θ'"
  },
  {
    "code": "1110",
    "name": "Δ΄(Δ΄,Η΄)ΑΘΗΝΩΝ(Ι΄,Δ΄,Η΄,ΙΑ΄ ΑΘΗΝΩΝ)"
  },
  {
    "code": "1111",
    "name": "ΑΘΗΝΩΝ ΙΑ'"
  },
  {
    "code": "1112",
    "name": "ΙΒ ΑΘΗΝΩΝ"
  },
  {
    "code": "1113",
    "name": "ΙΓ ΑΘΗΝΩΝ"
  },
  {
    "code": "1114",
    "name": "ΑΘΗΝΩΝ ΙΔ'"
  },
  {
    "code": "1115",
    "name": "ΑΘΗΝΩΝ ΙΕ'"
  },
  {
    "code": "1116",
    "name": "ΑΘΗΝΩΝ ΙΣΤ'"
  },
  {
    "code": "1117",
    "name": "ΙΖ ΑΘΗΝΩΝ"
  },
  {
    "code": "1118",
    "name": "ΜΕΓΑΛΩΝ ΕΠΙΧΕΙΡΗΣΕΩΝ"
  },
  {
    "code": "1123",
    "name": "ΕΛΕΥΘ.ΕΠΑΓΓ. Α'"
  },
  {
    "code": "1124",
    "name": "ΑΘΗΝΩΝ ΙΗ'"
  },
  {
    "code": "1125",
    "name": "Κατοίκων Εξωτερικού"
  },
  {
    "code": "1126",
    "name": "ΑΘΗΝΩΝ ΙΘ'"
  },
  {
    "code": "1129",
    "name": "ΑΓΙΟΥ ΔΗΜΗΤΡΙΟΥ"
  },
  {
    "code": "1130",
    "name": "ΚΑΛΛΙΘΕΑΣ"
  },
  {
    "code": "1131",
    "name": "ΝΕΑΣ ΙΩΝΙΑΣ"
  },
  {
    "code": "1132",
    "name": "Ν.ΣΜΥΡΝΗΣ"
  },
  {
    "code": "1133",
    "name": "ΠΑΛΑΙΟΥ ΦΑΛΗΡΟΥ"
  },
  {
    "code": "1134",
    "name": "ΧΑΛΑΝΔΡΙΟΥ"
  },
  {
    "code": "1135",
    "name": "ΑΜΑΡΟΥΣΙΟΥ"
  },
  {
    "code": "1136",
    "name": "ΑΓΙΩΝ ΑΝΑΡΓΥΡΩΝ"
  },
  {
    "code": "1137",
    "name": "ΑΙΓΑΛΕΩ"
  },
  {
    "code": "1138",
    "name": "Α΄ ΠΕΡΙΣΤΕΡΙΟΥ"
  },
  {
    "code": "1139",
    "name": "ΓΛΥΦΑΔΑΣ"
  },
  {
    "code": "1140",
    "name": "ΑΘΗΝΩΝ Κ'"
  },
  {
    "code": "1141",
    "name": "ΑΘΗΝΩΝ ΚΑ'"
  },
  {
    "code": "1142",
    "name": "ΑΘΗΝΩΝ ΚΒ'"
  },
  {
    "code": "1143",
    "name": "ΑΘΗΝΩΝ ΚΓ'"
  },
  {
    "code": "1144",
    "name": "ΔΑΦΝΗΣ"
  },
  {
    "code": "1145",
    "name": "Ν.ΗΡΑΚΛΕΙΟΥ"
  },
  {
    "code": "1147",
    "name": "ΚΒ' ΑΘΗΝΩΝ"
  },
  {
    "code": "1151",
    "name": "ΧΟΛΑΡΓΟΥ"
  },
  {
    "code": "1152",
    "name": "ΒΥΡΩΝΑ"
  },
  {
    "code": "1153",
    "name": "ΚΗΦΙΣΙΑΣ"
  },
  {
    "code": "1154",
    "name": "ΙΛΙΟΥ"
  },
  {
    "code": "1155",
    "name": "ΝΕΑΣ ΙΩΝΙΑΣ(Ν.ΦΙΛΑΔΕΛΦΕΙΑΣ,ΝΕΑΣ ΙΩΝΙΑΣ)"
  },
  {
    "code": "1156",
    "name": "ΧΑΙΔΑΡΙΟΥ"
  },
  {
    "code": "1157",
    "name": "Β΄ ΠΕΡΙΣΤΕΡΙΟΥ(Β΄ ΠΕΡΙΣΤΕΡΙΟΥ,ΠΕΤΡΟΥΠΟΛΗΣ)"
  },
  {
    "code": "1158",
    "name": "ΠΕΡΙΣΤΕΡΙΟΥ"
  },
  {
    "code": "1159",
    "name": "ΦΑΕ ΑΘΗΝΩΝ"
  },
  {
    "code": "1161",
    "name": "ΚΕΦΑΛΑΙΟΥ ΑΘ. Α'"
  },
  {
    "code": "1162",
    "name": "ΚΕΦΑΛ. ΑΘΗΝΩΝ Β'"
  },
  {
    "code": "1164",
    "name": "ΚΕΦΑΛΑΙΟΥ ΠΕΙΡΑΙΑ"
  },
  {
    "code": "1172",
    "name": "ΖΩΓΡΑΦΟΥ"
  },
  {
    "code": "1173",
    "name": "ΗΛΙΟΥΠΟΛΗΣ"
  },
  {
    "code": "1174",
    "name": "ΚΑΛΛΙΘΕΑΣ Β'"
  },
  {
    "code": "1175",
    "name": "ΨΥΧΙΚΟΥ"
  },
  {
    "code": "1176",
    "name": "ΧΟΛΑΡΓΟΥ"
  },
  {
    "code": "1177",
    "name": "ΗΛΙΟΥΠΟΛΗΣ(ΑΡΓΥΡΟΥΠΟΛΗΣ,ΗΛΙΟΥΠΟΛΗΣ)"
  },
  {
    "code": "1178",
    "name": "ΠΕΤΡΟΥΠΟΛΕΩΣ"
  },
  {
    "code": "1179",
    "name": "Γαλατσίου"
  },
  {
    "code": "1180",
    "name": "ΑΝΩ ΛΙΟΣΙΩΝ"
  },
  {
    "code": "1190",
    "name": "ΚΕΦΟΔΕ ΑΤΤΙΚΗΣ"
  },
  {
    "code": "1201",
    "name": "Α ΠΕΙΡΑΙΑ"
  },
  {
    "code": "1202",
    "name": "ΠΕΙΡΑΙΑ Β'"
  },
  {
    "code": "1203",
    "name": "ΠΕΙΡΑΙΑ Γ'"
  },
  {
    "code": "1204",
    "name": "Δ΄ ΠΕΙΡΑΙΑ(Δ΄,ΣΤ΄ ΠΕΙΡΑΙΑ)"
  },
  {
    "code": "1205",
    "name": "Ε ΠΕΙΡΑΙΑ"
  },
  {
    "code": "1206",
    "name": "ΦΑΕ ΠΕΙΡΑΙΑ"
  },
  {
    "code": "1207",
    "name": "ΠΛΟΙΩΝ ΠΕΙΡΑΙΑ"
  },
  {
    "code": "1208",
    "name": "ΠΕΙΡΑΙΑ ΣΤ'"
  },
  {
    "code": "1209",
    "name": "Δ΄ ΠΕΙΡΑΙΑ(ΣΤ΄,Δ΄ ΠΕΙΡΑΙΑ)"
  },
  {
    "code": "1210",
    "name": "ΚΟΡΥΔΑΛΛΟΥ"
  },
  {
    "code": "1211",
    "name": "ΜΟΣΧΑΤΟΥ"
  },
  {
    "code": "1212",
    "name": "ΕΝΣ/ΜΩΝ ΔΙΚ /ΕΙΣ ΠΕΙΡΑΙΩΣ"
  },
  {
    "code": "1213",
    "name": "ΓΕΝ.ΕΣΟΔΩΝ ΠΕΙΡΑΙΩΣ"
  },
  {
    "code": "1220",
    "name": "ΝΙΚΑΙΑΣ"
  },
  {
    "code": "1301",
    "name": "ΑΙΓΙΝΑΣ"
  },
  {
    "code": "1302",
    "name": "ΑΧΑΡΝΩΝ"
  },
  {
    "code": "1303",
    "name": "ΕΛΕΥΣΙΝΑΣ"
  },
  {
    "code": "1304",
    "name": "ΚΟΡΩΠΙΟΥ"
  },
  {
    "code": "1305",
    "name": "ΚΥΘΗΡΩΝ"
  },
  {
    "code": "1306",
    "name": "ΛΑΥΡΙΟΥ"
  },
  {
    "code": "1307",
    "name": "ΑΓΙΟΥ ΣΤΕΦΑΝΟΥ"
  },
  {
    "code": "1308",
    "name": "ΕΛΕΥΣΙΝΑΣ(ΜΕΓΑΡΩΝ,ΕΛΕΥΣΙΝΑΣ)"
  },
  {
    "code": "1309",
    "name": "ΣΑΛΑΜΙΝΑΣ"
  },
  {
    "code": "1310",
    "name": "ΠΟΡΟΥ"
  },
  {
    "code": "1312",
    "name": "ΠΑΛΛΗΝΗΣ"
  },
  {
    "code": "1411",
    "name": "ΘΗΒΩΝ"
  },
  {
    "code": "1421",
    "name": "ΛΙΒΑΔΕΙΑΣ"
  },
  {
    "code": "1511",
    "name": "ΑΜΦΙΛΟΧΙΑΣ"
  },
  {
    "code": "1521",
    "name": "ΑΣΤΑΚΟΥ"
  },
  {
    "code": "1522",
    "name": "ΒΟΝΙΤΣΑΣ"
  },
  {
    "code": "1531",
    "name": "ΜΕΣΟΛΟΓΓΙΟΥ"
  },
  {
    "code": "1541",
    "name": "ΝΑΥΠΑΚΤΟΥ"
  },
  {
    "code": "1551",
    "name": "ΘΕΡΜΟΥ"
  },
  {
    "code": "1552",
    "name": "ΑΓΡΙΝΙΟΥ"
  },
  {
    "code": "1611",
    "name": "ΚΑΡΠΕΝΗΣΙΟΥ"
  },
  {
    "code": "1711",
    "name": "ΙΣΤΙΑΙΑΣ"
  },
  {
    "code": "1721",
    "name": "ΚΑΡΥΣΤΟΥ"
  },
  {
    "code": "1722",
    "name": "ΚΥΜΗΣ"
  },
  {
    "code": "1731",
    "name": "ΛΙΜΝΗΣ"
  },
  {
    "code": "1732",
    "name": "ΧΑΛΚΙΔΑΣ"
  },
  {
    "code": "1811",
    "name": "ΔΟΜΟΚΟΥ"
  },
  {
    "code": "1812",
    "name": "ΑΜΦΙΣΣΗΣ"
  },
  {
    "code": "1821",
    "name": "ΑΜΦΙΚΛΕΙΑΣ"
  },
  {
    "code": "1822",
    "name": "ΑΤΑΛΑΝΤΗΣ"
  },
  {
    "code": "1831",
    "name": "ΜΑΚΡΑΚΩΜΗΣ"
  },
  {
    "code": "1832",
    "name": "ΛΑΜΙΑΣ"
  },
  {
    "code": "1833",
    "name": "Στυλίδας"
  },
  {
    "code": "1911",
    "name": "ΛΙΔΟΡΙΚΙΟΥ"
  },
  {
    "code": "1912",
    "name": "ΑΜΦΙΣΣΑΣ"
  },
  {
    "code": "2111",
    "name": "ΑΡΓΟΥΣ"
  },
  {
    "code": "2112",
    "name": "ΑΡΓΟΥΣ ΟΡΕΣΤΙΚΟΥ"
  },
  {
    "code": "2121",
    "name": "ΣΠΕΤΣΩΝ"
  },
  {
    "code": "2122",
    "name": "ΚΡΑΝΙΔΙΟΥ"
  },
  {
    "code": "2123",
    "name": "ΣΤΥΛΙΔΑΣ"
  },
  {
    "code": "2124",
    "name": "ΥΔΡΑΣ"
  },
  {
    "code": "2131",
    "name": "ΝΑΥΠΛΙΟΥ"
  },
  {
    "code": "2211",
    "name": "ΔΗΜΗΤΣΑΝΑΣ"
  },
  {
    "code": "2213",
    "name": "ΛΕΩΝΙΔΙΟΥ"
  },
  {
    "code": "2214",
    "name": "ΤΡΟΠΑΙΩΝ"
  },
  {
    "code": "2221",
    "name": "ΑΣΤΡΟΥΣ"
  },
  {
    "code": "2231",
    "name": "ΤΡΙΠΟΛΗΣ"
  },
  {
    "code": "2241",
    "name": "ΜΕΓΑΛΟΠΟΛΗΣ"
  },
  {
    "code": "2311",
    "name": "ΑΙΓΙΟΥ"
  },
  {
    "code": "2312",
    "name": "ΑΚΡΑΤΑΣ"
  },
  {
    "code": "2321",
    "name": "ΚΑΛΑΒΡΥΤΩΝ"
  },
  {
    "code": "2322",
    "name": "ΚΛΕΙΤΟΡΙΑΣ"
  },
  {
    "code": "2324",
    "name": "Γ' ΠΑΤΡΩΝ"
  },
  {
    "code": "2331",
    "name": "ΠΑΤΡΩΝ"
  },
  {
    "code": "2332",
    "name": "Γ΄ ΠΑΤΡΩΝ(Β΄,Γ΄ ΠΑΤΡΩΝ,ΚΑΤΩ ΑΧΑΙΑΣ)"
  },
  {
    "code": "2333",
    "name": "ΚΑΤΩ ΑΧΑΙΑΣ"
  },
  {
    "code": "2334",
    "name": "Γ ΠΑΤΡΩΝ"
  },
  {
    "code": "2411",
    "name": "ΑΜΑΛΙΑΔΑΣ"
  },
  {
    "code": "2412",
    "name": "ΠΥΡΓΟΥ"
  },
  {
    "code": "2413",
    "name": "ΓΑΣΤΟΥΝΗΣ"
  },
  {
    "code": "2414",
    "name": "ΒΑΡΔΑ"
  },
  {
    "code": "2415",
    "name": "ΑΜΠΕΛΟΚΗΠΩΝ ΘΕΣ/ΝΙΚΗ"
  },
  {
    "code": "2416",
    "name": "Π. ΦΑΛΗΡΟΥ"
  },
  {
    "code": "2421",
    "name": "ΚΡΕΣΤΕΝΩΝ"
  },
  {
    "code": "2422",
    "name": "ΛΕΧΑΙΝΩΝ"
  },
  {
    "code": "2423",
    "name": "ΑΝΔΡΙΤΣΑΙΝΑΣ"
  },
  {
    "code": "2424",
    "name": "ΖΑΧΑΡΩΣ"
  },
  {
    "code": "2432",
    "name": "ΧΑΝΙΩΝ Β΄"
  },
  {
    "code": "2511",
    "name": "ΔΕΡΒΕΝΙΟΥ"
  },
  {
    "code": "2512",
    "name": "ΚΙΑΤΟΥ"
  },
  {
    "code": "2513",
    "name": "ΚΟΡΙΝΘΟΥ"
  },
  {
    "code": "2514",
    "name": "ΝΕΜΕΑΣ"
  },
  {
    "code": "2515",
    "name": "ΞΥΛΟΚΑΣΤΡΟΥ"
  },
  {
    "code": "2611",
    "name": "ΓΥΘΕΙΟΥ"
  },
  {
    "code": "2621",
    "name": "ΜΟΛΑΩΝ"
  },
  {
    "code": "2622",
    "name": "Νεάπολης Βοιών (Λακωνίας)"
  },
  {
    "code": "2630",
    "name": "ΣΚΑΛΑΣ"
  },
  {
    "code": "2631",
    "name": "ΚΡΟΚΕΩΝ"
  },
  {
    "code": "2632",
    "name": "ΣΠΑΡΤΗΣ"
  },
  {
    "code": "2633",
    "name": "ΝΕΑΠΟΛΕΩΣ ΛΑΚΩΝΙΑΣ"
  },
  {
    "code": "2641",
    "name": "ΑΡΕΟΠΟΛΗΣ"
  },
  {
    "code": "2711",
    "name": "ΚΑΛΑΜΑΤΑΣ"
  },
  {
    "code": "2721",
    "name": "ΜΕΛΙΓΑΛΑ"
  },
  {
    "code": "2722",
    "name": "ΜΕΣΣΗΝΗΣ"
  },
  {
    "code": "2731",
    "name": "ΠΥΛΟΥ"
  },
  {
    "code": "2741",
    "name": "ΓΑΡΓΑΛΙΑΝΩΝ"
  },
  {
    "code": "2742",
    "name": "ΚΥΠΑΡΙΣΣΙΑΣ"
  },
  {
    "code": "2743",
    "name": "ΦΙΛΙΑΤΡΩΝ"
  },
  {
    "code": "3111",
    "name": "ΚΑΡΔΙΤΣΑΣ"
  },
  {
    "code": "3112",
    "name": "ΜΟΥΖΑΚΙΟΥ"
  },
  {
    "code": "3113",
    "name": "ΣΟΦΑΔΩΝ"
  },
  {
    "code": "3114",
    "name": "ΠΑΛΑΜΑ ΚΑΡΔΙΤΣΑΣ"
  },
  {
    "code": "3211",
    "name": "ΑΓΙΑΣ"
  },
  {
    "code": "3221",
    "name": "ΕΛΑΣΣΟΝΑΣ"
  },
  {
    "code": "3222",
    "name": "ΔΕΣΚΑΤΗΣ"
  },
  {
    "code": "3231",
    "name": "ΛΑΡΙΣΑΣ"
  },
  {
    "code": "3232",
    "name": "ΛΑΡΙΣΑΣ Β'"
  },
  {
    "code": "3233",
    "name": "ΛΑΡΙΣΗΣ Γ'"
  },
  {
    "code": "3234",
    "name": "ΛΑΡΙΣΑΣ Β'"
  },
  {
    "code": "3241",
    "name": "ΤΥΡΝΑΒΟΥ"
  },
  {
    "code": "3251",
    "name": "ΦΑΡΣΑΛΩΝ"
  },
  {
    "code": "3311",
    "name": "ΑΛΜΥΡΟΥ"
  },
  {
    "code": "3321",
    "name": "ΒΟΛΟΥ"
  },
  {
    "code": "3322",
    "name": "ΒΟΛΟΥ Β'"
  },
  {
    "code": "3323",
    "name": "ΙΩΝΙΑΣ ΜΑΓΝΗΣΙΑΣ"
  },
  {
    "code": "3331",
    "name": "ΣΚΟΠΕΛΟΥ"
  },
  {
    "code": "3332",
    "name": "ΣΚΙΑΘΟΥ"
  },
  {
    "code": "3411",
    "name": "ΚΑΛΑΜΠΑΚΑΣ"
  },
  {
    "code": "3412",
    "name": "ΤΡΙΚΑΛΩΝ"
  },
  {
    "code": "3490",
    "name": "ΠΥΛΗΣ ΤΡΙΚΑΛΩΝ"
  },
  {
    "code": "4111",
    "name": "ΑΛΕΞΑΝΔΡΕΙΑΣ"
  },
  {
    "code": "4112",
    "name": "ΒΕΡΟΙΑΣ"
  },
  {
    "code": "4121",
    "name": "ΝΑΟΥΣΑΣ"
  },
  {
    "code": "4211",
    "name": "Α ΘΕΣΣΑΛΟΝΙΚΗΣ"
  },
  {
    "code": "4212",
    "name": "ΘΕΣΣΑΛΟΝΙΚΗΣ Β'"
  },
  {
    "code": "4213",
    "name": "ΘΕΣΣΑΛΟΝΙΚΗΣ Γ'"
  },
  {
    "code": "4214",
    "name": "Δ ΘΕΣΣΑΛΟΝΙΚΗΣ"
  },
  {
    "code": "4215",
    "name": "Ε ΘΕΣΣΑΛΟΝΙΚΗΣ"
  },
  {
    "code": "4216",
    "name": "ΘΕΣΣΑΛΟΝΙΚΗΣ ΣΤ'"
  },
  {
    "code": "4217",
    "name": "Ζ ΘΕΣΣΑΛΟΝΙΚΗΣ"
  },
  {
    "code": "4219",
    "name": "ΚΕΦΑΛΑΙΟΥ ΘΕΣ/ΝΙΚΗΣ"
  },
  {
    "code": "4221",
    "name": "ΖΑΓΚΛΙΒΕΡΙΟΥ"
  },
  {
    "code": "4222",
    "name": "ΛΑΓΚΑΔΑ"
  },
  {
    "code": "4223",
    "name": "ΣΟΧΟΥ"
  },
  {
    "code": "4224",
    "name": "ΦΑΕ ΘΕΣΣΑΛΟΝΙΚΗΣ"
  },
  {
    "code": "4225",
    "name": "ΝΕΑΠΟΛΗΣ ΘΕΣ/ΝΙΚΗΣ"
  },
  {
    "code": "4226",
    "name": "ΤΟΥΜΠΑΣ ΘΕΣ/ΝΙΚΗΣ"
  },
  {
    "code": "4227",
    "name": "ΘΕΣΣΑΛΟΝΙΚΗΣ Ι'"
  },
  {
    "code": "4228",
    "name": "ΘΕΣΣΑΛΟΝΙΚΗΣ Η'"
  },
  {
    "code": "4229",
    "name": "ΘΕΣΣΑΛΟΝΙΚΗΣ Θ'"
  },
  {
    "code": "4231",
    "name": "ΑΓ.ΑΘΑΝΑΣΙΟΥ ΘΕΣ/ΝΙΚΗΣ"
  },
  {
    "code": "4232",
    "name": "ΚΑΛΑΜΑΡΙΑΣ"
  },
  {
    "code": "4233",
    "name": "ΑΜΠΕΛΟΚΗΠΩΝ"
  },
  {
    "code": "4234",
    "name": "ΙΩΝΙΑΣ ΘΕΣΣΑΛΟΝΙΚΗΣ"
  },
  {
    "code": "4311",
    "name": "ΚΑΣΤΟΡΙΑΣ"
  },
  {
    "code": "4312",
    "name": "ΝΕΣΤΟΡΙΟΥ"
  },
  {
    "code": "4313",
    "name": "ΑΡΓΟΥΣ ΟΡΕΣΤΙΚΟΥ"
  },
  {
    "code": "4411",
    "name": "ΚΙΛΚΙΣ"
  },
  {
    "code": "4421",
    "name": "ΓΟΥΜΕΝΙΣΣΑΣ"
  },
  {
    "code": "4511",
    "name": "ΝΕΑΠΟΛΗΣ ΒΟΙΟΥ"
  },
  {
    "code": "4521",
    "name": "ΓΡΕΒΕΝΩΝ"
  },
  {
    "code": "4531",
    "name": "ΠΤΟΛΕΜΑΙΔΑΣ"
  },
  {
    "code": "4541",
    "name": "ΚΟΖΑΝΗΣ"
  },
  {
    "code": "4542",
    "name": "ΣΕΡΒΙΩΝ"
  },
  {
    "code": "4543",
    "name": "ΣΙΑΤΙΣΤΑΣ"
  },
  {
    "code": "4611",
    "name": "ΑΡΙΔΑΙΑΣ"
  },
  {
    "code": "4621",
    "name": "ΓΙΑΝΝΙΤΣΩΝ"
  },
  {
    "code": "4631",
    "name": "ΕΔΕΣΣΑΣ"
  },
  {
    "code": "4641",
    "name": "ΣΚΥΔΡΑΣ"
  },
  {
    "code": "4711",
    "name": "ΚΑΤΕΡΙΝΗΣ"
  },
  {
    "code": "4712",
    "name": "Β' ΚΑΤΕΡΙΝΗΣ"
  },
  {
    "code": "4714",
    "name": "ΑΙΓΙΝΙΟΥ"
  },
  {
    "code": "4811",
    "name": "ΑΜΥΝΤΑΙΟΥ"
  },
  {
    "code": "4812",
    "name": "ΦΛΩΡΙΝΑΣ"
  },
  {
    "code": "4911",
    "name": "ΑΡΝΑΙΑΣ"
  },
  {
    "code": "4921",
    "name": "ΚΑΣΣΑΝΔΡΑΣ"
  },
  {
    "code": "4922",
    "name": "ΠΟΛΥΓΥΡΟΥ"
  },
  {
    "code": "4923",
    "name": "ΝΕΩΝ ΜΟΥΔΑΝΙΩΝ"
  },
  {
    "code": "5111",
    "name": "ΔΡΑΜΑΣ"
  },
  {
    "code": "5112",
    "name": "ΝΕΥΡΟΚΟΠΙΟΥ"
  },
  {
    "code": "5211",
    "name": "ΑΛΕΞΑΝΔΡΟΥΠΟΛΗΣ"
  },
  {
    "code": "5221",
    "name": "ΔΙΔΥΜΟΤΕΙΧΟΥ"
  },
  {
    "code": "5231",
    "name": "ΟΡΕΣΤΙΑΔΑΣ"
  },
  {
    "code": "5241",
    "name": "ΣΟΥΦΛΙΟΥ"
  },
  {
    "code": "5311",
    "name": "ΘΑΣΟΥ"
  },
  {
    "code": "5321",
    "name": "ΚΑΒΑΛΑΣ"
  },
  {
    "code": "5322",
    "name": "Β' ΚΑΒΑΛΑΣ"
  },
  {
    "code": "5323",
    "name": "ΑΒ ΚΑΒΑΛΑΣ"
  },
  {
    "code": "5331",
    "name": "ΧΡΥΣΟΥΠΟΛΗΣ"
  },
  {
    "code": "5341",
    "name": "ΕΛΕΥΘΕΡΟΥΠΟΛΗΣ"
  },
  {
    "code": "5411",
    "name": "ΞΑΝΘΗΣ"
  },
  {
    "code": "5412",
    "name": "Β' ΞΑΝΘΗΣ"
  },
  {
    "code": "5511",
    "name": "ΚΟΜΟΤΗΝΗΣ"
  },
  {
    "code": "5521",
    "name": "ΣΑΠΠΩΝ"
  },
  {
    "code": "5611",
    "name": "ΝΙΓΡΙΤΑΣ"
  },
  {
    "code": "5621",
    "name": "ΣΕΡΡΩΝ"
  },
  {
    "code": "5622",
    "name": "Β' ΣΕΡΡΩΝ"
  },
  {
    "code": "5631",
    "name": "ΣΙΔΗΡΟΚΑΣΤΡΟΥ"
  },
  {
    "code": "5632",
    "name": "ΗΡΑΚΛΕΙΑΣ"
  },
  {
    "code": "5641",
    "name": "ΝΕΑΣ ΖΙΧΝΗΣ"
  },
  {
    "code": "6111",
    "name": "ΑΡΤΑΣ"
  },
  {
    "code": "6113",
    "name": "Φιλιππιάδας"
  },
  {
    "code": "6211",
    "name": "ΗΓΟΥΜΕΝΙΤΣΑΣ"
  },
  {
    "code": "6212",
    "name": "ΦΙΛΙΠΠΙΑΔΑΣ"
  },
  {
    "code": "6221",
    "name": "ΠΑΡΓΑΣ"
  },
  {
    "code": "6231",
    "name": "ΠΑΡΑΜΥΘΙΑΣ"
  },
  {
    "code": "6241",
    "name": "ΦΙΛΙΑΤΩΝ"
  },
  {
    "code": "6311",
    "name": "ΙΩΑΝΝΙΝΩΝ"
  },
  {
    "code": "6312",
    "name": "ΙΩΑΝΝΙΝΩΝ Β'"
  },
  {
    "code": "6313",
    "name": "Δελβινακίου"
  },
  {
    "code": "6315",
    "name": "ΜΕΤΣΟΒΟΥ"
  },
  {
    "code": "6321",
    "name": "ΚΟΝΙΤΣΑΣ"
  },
  {
    "code": "6411",
    "name": "ΠΡΕΒΕΖΑΣ"
  },
  {
    "code": "7111",
    "name": "ΑΝΔΡΟΥ"
  },
  {
    "code": "7121",
    "name": "ΘΗΡΑΣ"
  },
  {
    "code": "7131",
    "name": "ΚΕΑΣ"
  },
  {
    "code": "7141",
    "name": "ΜΗΛΟΥ"
  },
  {
    "code": "7151",
    "name": "ΝΑΞΟΥ"
  },
  {
    "code": "7161",
    "name": "ΠΑΡΟΥ"
  },
  {
    "code": "7171",
    "name": "ΣΥΡΟΥ"
  },
  {
    "code": "7172",
    "name": "ΜΥΚΟΝΟΥ"
  },
  {
    "code": "7181",
    "name": "ΤΗΝΟΥ"
  },
  {
    "code": "7211",
    "name": "ΛΗΜΝΟΥ"
  },
  {
    "code": "7212",
    "name": "ΛΕΡΟΥ"
  },
  {
    "code": "7221",
    "name": "ΚΑΛΛΟΝΗΣ"
  },
  {
    "code": "7222",
    "name": "ΜΗΘΥΜΝΑΣ"
  },
  {
    "code": "7231",
    "name": "ΜΥΤΙΛΗΝΗΣ"
  },
  {
    "code": "7241",
    "name": "ΠΛΩΜΑΡΙΟΥ"
  },
  {
    "code": "7311",
    "name": "ΑΓ. ΚΗΡΥΚΟΥ ΙΚΑΡΙΑΣ"
  },
  {
    "code": "7321",
    "name": "ΚΑΡΛΟΒΑΣΙΟΥ"
  },
  {
    "code": "7322",
    "name": "ΣΑΜΟΥ"
  },
  {
    "code": "7411",
    "name": "ΧΙΟΥ"
  },
  {
    "code": "7511",
    "name": "ΚΑΛΥΜΝΟΥ"
  },
  {
    "code": "7512",
    "name": "Λέρου"
  },
  {
    "code": "7514",
    "name": "ΝΕΜΕΑΣ"
  },
  {
    "code": "7521",
    "name": "ΚΑΡΠΑΘΟΥ"
  },
  {
    "code": "7531",
    "name": "ΚΩ"
  },
  {
    "code": "7542",
    "name": "ΡΟΔΟΥ"
  },
  {
    "code": "7543",
    "name": "ΡΟΔΟΥ"
  },
  {
    "code": "8110",
    "name": "ΗΡΑΚΛΕΙΟΥ"
  },
  {
    "code": "8111",
    "name": "ΗΡΑΚΛΕΙΟΥ Α'"
  },
  {
    "code": "8112",
    "name": "ΜΟΙΡΩΝ"
  },
  {
    "code": "8113",
    "name": "Β΄ ΗΡΑΚΛΕΙΟΥ(Β΄ ΗΡΑΚΛΕΙΟΥ,ΑΡΚΑΛ/ΡΙΟΥ,ΚΑΣΤΕΛΛΙΟΥ ΠΕΔ,Λ.ΧΕΡΣΟΝ"
  },
  {
    "code": "8114",
    "name": "ΤΥΜΠΑΚΙΟΥ ΗΡΑΚΛΕΙΟΥ"
  },
  {
    "code": "8115",
    "name": "ΛΙΜΕΝΑΣ ΧΕΡΣΟΝΗΣΟΥ"
  },
  {
    "code": "8121",
    "name": "ΚΑΣΤΕΛΙΟΥ ΠΕΔΙΑΔΟΣ"
  },
  {
    "code": "8122",
    "name": "ΜΟΙΡΩΝ"
  },
  {
    "code": "8131",
    "name": "ΑΡΚΑΛΟΧΩΡΙΟΥ"
  },
  {
    "code": "8211",
    "name": "ΙΕΡΑΠΕΤΡΑΣ"
  },
  {
    "code": "8221",
    "name": "ΑΓΙΟΥ ΝΙΚΟΛΑΟΥ"
  },
  {
    "code": "8231",
    "name": "ΝΕΑΠΟΛΗΣ ΚΡΗΤΗΣ"
  },
  {
    "code": "8241",
    "name": "ΣΗΤΕΙΑΣ"
  },
  {
    "code": "8341",
    "name": "ΡΕΘΥΜΝΟΥ"
  },
  {
    "code": "8421",
    "name": "ΚΑΣΤΕΛΙΟΥ ΚΙΣΣΑΜΟΥ"
  },
  {
    "code": "8431",
    "name": "ΧΑΝΙΩΝ"
  },
  {
    "code": "8432",
    "name": "Β΄ ΧΑΝΙΩΝ"
  },
  {
    "code": "9111",
    "name": "ΖΑΚΥΝΘΟΥ"
  },
  {
    "code": "9211",
    "name": "ΚΕΡΚΥΡΑΣ"
  },
  {
    "code": "9212",
    "name": "Β' ΚΕΡΚΥΡΑΣ"
  },
  {
    "code": "9221",
    "name": "ΠΑΞΩΝ"
  },
  {
    "code": "9311",
    "name": "ΑΡΓΟΣΤΟΛΙΟΥ"
  },
  {
    "code": "9321",
    "name": "ΛΗΞΟΥΡΙΟΥ"
  },
  {
    "code": "9411",
    "name": "ΙΘΑΚΗΣ"
  },
  {
    "code": "9421",
    "name": "ΛΕΥΚΑΔΑΣ"
  },
  {
    "code": "9422",
    "name": "ΣΕΡΡΩΝ Β"
  },
  {
    "code": "9423",
    "name": "ΦΑΡΚΑΔΟΝΑΣ"
  },
  {
    "code": "9424",
    "name": "ΣΕΡΡΩΝ"
  },
  {
    "code": "9999",
    "name": "ΦΑΝΑΡΙΟΥ"
  }
]